import React, { useRef } from 'react';
import styled from 'styled-components';

import Feature from '../components/Feature/Feature';
import LinkButton from '../components/Buttons/LinkButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';

import HeroBackground from '../assets/img/features-hero-background.jpg';
import Slide1 from '../assets/img/creator-create-info-point.png';
import Slide2 from '../assets/img/creator-create-poi-map.png';
import Slide3 from '../assets/img/creator-create-questionnaire.png';
import Slide4 from '../assets/img/creator-poi-info-point-questionnaire.png';
import Slide5 from '../assets/img/creator-poi-portal.png';
import {Image360Icon, AddIcon, AnalyticsIcon, ChecklistIcon, DragDropIcon, MediaIcon, ScreenIcon, TeleportIcon, PrivateIcon, MultiplatformIcon, ChevronIcon } from '../assets/icons';
import SEO from '../components/SEO/SEO';

SwiperCore.use([Navigation]);

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem;
  background: url(${HeroBackground}) center / cover no-repeat;
  border-radius: var(--border-radius-surface) var(--border-radius-surface) 0 0;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60rem;
  width: 100%;
  max-width: var(--page-content-width);
`;

const FeaturesSection = styled.section`
  position: relative;
  margin-top: -2rem;
  padding: 12rem 2.4rem;
  background: var(--color-surface-light);
  border-radius: var(--border-radius-surface);
`;

const FeaturesSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto;
  h2 {
    margin-bottom: 6.4rem;
    color: var(--color-heading-dark);
  }
  a {
    margin-left: auto;
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6.4rem 2rem;
  div:last-child {
    grid-column: 1 / 3;
  }
  margin-bottom: 6.4rem;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;

const ShowcaseSection = styled.section`
  margin-top: -2rem;
  padding: 12rem 2.4rem;
  background: #E3EDEA;
  border-radius: 0 0 var(--border-radius-surface) var(--border-radius-surface);
`;

const ShowcaseSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto;
  h2 {
    margin-bottom: 6.4rem;
    color: var(--color-heading-dark);
  }
  a {
    margin-left: auto;
  }
`;

const SliderWrapper = styled.div`
  flex: 1;
`;

const SwiperNav = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  padding: 1.2rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

const FeaturesPage = ({ location }) => {
  const navigationPrev = useRef(null);
  const navigationNext = useRef(null);

  return (
    <>
      <SEO title='' description='From creating image, video, and audio hotspots to connecting 360 scenes in a cohesive experience - check out all the veerXR features!' location={location} />
      <Hero>
        <HeroContent>
            <h1>Features</h1>
            <p className='subtitle'>All the functionalities that our platform offers.</p>
        </HeroContent>
      </Hero>
      <FeaturesSection>
        <FeaturesSectionContent>
          {/* <div className='caption'>Create your own reality</div>
          <h2>Features</h2> */}
          <Features>
            <Feature icon={DragDropIcon} title='Simple Drag & Drop'>Our intuitive interface enables you to create a virtual tour through a drag and drop system. No coding necessary!</Feature>
            <Feature icon={Image360Icon} title='Upload Your Own 360 Image'>Our platform supports any panoramic equirectangular JPG or PNG file.</Feature>
            <Feature icon={ScreenIcon} title='Home Screen'>Select a starting screen that best fits your experience: a map or an image.</Feature>
            <Feature icon={MediaIcon} title='Embed Audio & Video'>Through using our Information Points, enrich your 360 scenes with text information, audio, or video files to wow your consumers!</Feature>
            <Feature icon={ChecklistIcon} title='Questionnaire'>Evaluate the performance of your employees or conduct a customer survey by adding Question Points to your 360 scenes!</Feature>
            <Feature icon={AnalyticsIcon} title='Analytics'>Our easy-to-follow analytics enable you to monitor your consumers’ behavior in the virtual tour and gain invaluable insights into their interests and knowledge.</Feature>
            <Feature icon={TeleportIcon} title='Travel Directly from Scene to Scene'>Create a seamless experience where your consumers can travel from one 360 scene to another without needing to return to the main screen.</Feature>
            <Feature icon={PrivateIcon} title='Public or Private'>Publish your experience for anyone to access, or make it private and share it only with specific users.</Feature>
            <Feature icon={MultiplatformIcon} title='Virtual Tour for Any Device'>Our easy-to-follow analytics enable you to monitor your consumers’ behavior in the virtual tour and gain invaluable insights into their interests and knowledge.</Feature>
            <Feature icon={AddIcon} title='Add a New Feature'>If you don’t see a feature you need in our list, don’t hesitate to contact us and request a quote for adding a new feature for your business!</Feature>
          </Features>
        </FeaturesSectionContent>
      </FeaturesSection>

      <ShowcaseSection>
        <ShowcaseSectionContent>
          <div className='caption'>Showcase</div>
          <h2>Here are some key elements</h2>
          <SliderWrapper>
            <Swiper spaceBetween={50}
                    onInit={(swiper) => {
                      swiper.params.navigation.prevEl = navigationPrev.current;
                      swiper.params.navigation.nextEl = navigationNext.current;
                      swiper.navigation.update();
                    }}
            >
              <SwiperSlide>
                <img src={Slide1} alt='Interface of the veerXR platform showing how to add an info point with a title, text, audio, and video files.' style={{maxWidth: '100%'}} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Slide2} alt='Interface of the veerXR platform showing the map view when adding a map scene.' style={{maxWidth: '100%'}} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Slide3} alt='Interface of the veerXR platform showing how to add a question and answers for an interactive quiz.' style={{maxWidth: '100%'}} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Slide4} alt='Interface of the veerXR platform showing a 360 scene of a sun above some hills. The interface contains the flow chart, information panel, and main view.' style={{maxWidth: '100%'}} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Slide5} alt='Interface of the veerXR platform showing a 360 scene of an empty stadium. The interface contains the flow chart, information panel, and main view.' style={{maxWidth: '100%'}} />
              </SwiperSlide>

              <SwiperNav ref={navigationPrev} style={{left: '1.6rem', transform: 'translateY(-50%) rotate(180deg)'}} >
                <img src={ChevronIcon} alt="" />
              </SwiperNav>
              <SwiperNav ref={navigationNext} style={{right: '1.6rem', transformOrigin: 'center'}} >
                <img src={ChevronIcon} alt="" />
              </SwiperNav>
            </Swiper>
          </SliderWrapper>
          <LinkButton type='internal' href='/contact-us/'  margin='120px auto 0' backgroundColor='var(--color-green)' textColor='var(--color-body-dark)'>Request a quote</LinkButton>
        </ShowcaseSectionContent>
      </ShowcaseSection>
    </>
  )
}

export default FeaturesPage;
